<template>
  <div class="score-query" v-if="status.examStatus">
    <el-row class="score-query-title">
      <el-col>
        <el-icon class="btn" v-show="status.examStatus" @click="back"
          ><ArrowLeftBold /></el-icon
        >准考证打印</el-col
      >
    </el-row>
    <el-row>
      <el-col>
        <iframe :src="state.pdfUrl" class="iframe"></iframe>
        <!-- <pdf
            ref="pdf"
            :src="state.pdfUrl"
          ></pdf> -->
      </el-col>
    </el-row>
  </div>
  <div class="score-query" v-else>
    <el-row class="score-query-title" style="margin-bottom: 20px">
      <el-col>
        <div style="position: relative">
          <el-icon class="btn" @click="$router.push('/protal')"
            ><ArrowLeftBold
          /></el-icon>
        </div>
        准考证打印</el-col
      >
    </el-row>
    <el-row v-if="state.errorMessage">
      <el-col
        ><div class="errorMessage">{{ state.errorMessage }}</div></el-col
      >
    </el-row>
    <el-row v-else class="message-content">
      <el-col>
        <div style="height: 75vh; overflow: hidden">
          <div
            class="message-item"
            v-for="(item, index) in state.examList"
            :key="index"
          >
            <div class="message-item-left">
              <div>考试名称：{{ item.examName }}</div>
              <div>
                考试时间：<span
                  >{{
                    moment(item.examStartTime).format(
                      "YYYY 年 MM 月 DD 日 HH:mm"
                    )
                  }}~{{
                    moment(item.examEndTime).format("YYYY 年 MM 月 DD 日 HH:mm")
                  }}</span
                >
              </div>
            </div>

            <div class="message-item-right">
              <el-button
                type="primary"
                v-if="item.printTicket !== 1"
                disabled
                @click="makePdf(item)"
                >未开放</el-button
              >
              <el-button type="primary" v-else @click="makePdf(item)"
                >预览</el-button
              >
            </div>
          </div>
        </div>
        <el-pagination
          :page-size="pagination.pageSize"
          layout="prev, pager, next"
          :total="pagination.total"
          style="float: right"
          @current-change="handleCurrentChange"
        />
      </el-col>
    </el-row>
  </div>
</template>
<script setup>

import { post, get } from "@/api/api";
import { onMounted, reactive, ref, watch } from "@vue/runtime-core";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import { ArrowLeftBold } from "@element-plus/icons-vue"
import moment from "moment";
import pdf from 'vue-pdf'
const fileDownload = require('js-file-download')
onMounted(async () => {
  getPersonalExamList();
});
//data
let state = reactive({
  testInfo: {}, // 考试信息
  examList: [], // 考试列表
  pdfUrl: ''
})
// 页面状态
let status = reactive({
  examStatus: false,
  errorMessage: ''
})
// 分页数据
let pagination = reactive({
  pageSize: 6,
  pageNo: 1,
  total: 0,
});
// methods
// 获取可打印准考证考试
const getPersonalExamList = async () => {
  let res = await get("/examApply/getPersonalExamList", pagination);
  if (res.code === 200) {
    state.examList = res.result.list
    pagination.total = res.result.total
    if (res.result.list.length == 0) {
      state.errorMessage = res.message
    } else {
      state.errorMessage = ''
    }
  } else {
    ElMessage.error(res.message)
  }

};
// 通过考试id查询准考证
const makePdf = async (row) => {
  let res = await post("/examApply/makePdf", { examId: row.examId }, 'blob');
  const blob = new window.Blob([res], {
    type: 'application/pdf;charset-UTF-8'
  });
  state.pdfUrl = URL.createObjectURL(blob)
  console.log(blob);
  // fileDownload(res, 'scenesCountInfoExport.pdf')
  status.examStatus = true
};
// 返回
const back = () => {
  status.examStatus = false
};
// 分页切换
const handleCurrentChange = (num) => {
  pagination.pageNo = num;
  getPersonalExamList()
};
</script>
<style lang="scss" scoped>
.score-query {
  height: 89vh;
  background-color: #ffffff;
}
.message-content {
  padding: 0 60px;
}
.score-query-title {
  text-align: center;
  height: 60px;
  line-height: 60px;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  border-bottom: 1px solid #e5e5e5;
  .btn {
    position: absolute;
    left: 0;
    width: 60px;
    height: 60px;
    font-size: 16px;
    cursor: pointer;
  }
}
.message-item {
  display: flex;
  justify-content: space-between;
  height: 10vh;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  margin-bottom: 20px;
  // cursor: pointer;
  padding-left: 20px;
  border-left: 4px solid #097afa;
  .message-item-left {
    width: 90%;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    div {
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      padding: 6px 0;
    }

    div:nth-child(2) span {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #666666;
    }
  }
  .message-item-right {
    width: 10%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-right: 20px;
  }
}

.el-button {
  margin: 0;
  background-color: #097afa;
}
.is-disabled,
.is-disabled:hover {
  border: none;
  color: #999999;
  background-color: #eeeeee;
}
.iframe {
  width: 100%;
  height: 83vh;
}
.errorMessage {
  height: 3.6979rem;
  line-height: 3.6979rem;
  font-size: 24px;
  color: #ccc;
  text-align: center;
}
</style>